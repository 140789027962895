import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../API';
import { mergeDeepRight } from 'ramda';

export const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
    const { checkoutId, projectId } = useParams();

    const apiCtx = useContext(ApiContext);

    const [ ctxData, setCtxData ] = useState({
        isLoading: true,
        isError: false,
        checkout: null
    });

    useEffect(() => {
        console.debug('Loading checkout context');
        (async () => {
            try {
                const res = await apiCtx.request({
                    query: 'GET_CHECKOUT',
                    variables: {
                        id: checkoutId
                    }
                });

                setCtxData({
                    isLoading: false,
                    ...res
                });
                console.debug('Checkout context loaded', res);
            } catch (err) {
                console.error(err);
                setCtxData({
                    isLoading: false,
                    isError: true
                })
            }
        })();
    }, []);

    const update = useCallback(data => {
        const newData = mergeDeepRight(ctxData.checkout, data);
        setCtxData({
            ...ctxData,
            checkout: newData
        })
    });

    const hasValidInformation = ctxData.checkout && (ctxData.checkout.email && (ctxData.checkout.requiresShipping ? ctxData.checkout.shippingAddress : ctxData.checkout.billingAddress));
    const hasValidShippingRate = ctxData.checkout && (!ctxData.checkout.requiresShipping || ctxData.checkout.shippingRate);

    return (
        <CartContext.Provider value={{
            ...ctxData,
            cart: ctxData.checkout,
            hasValidInformation,
            hasValidShippingRate,
            makePath: (path = '') => `/${projectId}/${checkoutId}${path}`,
            update: update
        }}>
            {children}
        </CartContext.Provider>
    )
}