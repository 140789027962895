import React, { useState } from 'react';
import { Form, FormItemGroup, SelectField, TextField } from '@platformapp/ui';
import { Countries } from '../util/geo';
import { extractGraphqlError } from '../util';
import { assoc, propOr } from 'ramda';

const POSTAL_CODE_TRANSLATIONS = {
    'GB': 'Postcode',
    'US': 'Zip code'
}

export const AddressForm = ({
    disabled,
    errors,
    onChange,
    showPhone,
    value
}) => {
    const [hasLine2, setHasLine2] = useState(value.line2);
    const handleChange = (e) => onChange(assoc(e.target.name, e.target.value, value));
    return (
        <Form>
            <TextField
                disabled={disabled}
                name="name"
                label="Full name"
                value={value.name}
                onChange={handleChange}
                error={extractGraphqlError([ 'name' ], errors)}
            />
            <SelectField
                disabled={disabled}
                name="country"
                label="Country"
                value={value.country}
                onChange={handleChange}
                options={Countries.map(({ abbreviation, country }) => ({ label: country, value: abbreviation }))}
                error={extractGraphqlError([ 'country' ], errors)}
            />
            <TextField
                disabled={disabled}
                name="line1"
                label="Address line 1"
                value={value.line1}
                onChange={handleChange}
                error={extractGraphqlError([ 'line1' ], errors)}
            />
            {!hasLine2 && (
                <button
                    className="text-gray-600 text-sm"
                    onClick={() => setHasLine2(true)}
                    type="button"
                >
                    + Add address line 2
                </button>
            )}
            {hasLine2 && (
                <TextField
                    disabled={disabled}
                    name="line2"
                    label="Address line 2 (optional)"
                    onChange={handleChange}
                    value={value.line2}
                    error={extractGraphqlError([ 'line2' ], errors)}
                />
            )}
            <FormItemGroup>
                <TextField
                    disabled={disabled}
                    name="city"
                    label="City"
                    onChange={handleChange}
                    value={value.city}
                    error={extractGraphqlError([ 'city' ], errors)}
                />
                <TextField
                    disabled={disabled}
                    name="postalCode"
                    label={propOr('Postal code', value.country, POSTAL_CODE_TRANSLATIONS)}
                    value={value.postalCode}
                    onChange={handleChange}
                    error={extractGraphqlError([ 'postalCode' ], errors)}
                />
            </FormItemGroup>
            {showPhone && (
                <TextField
                    disabled={disabled}
                    name="phone"
                    label="Phone (optional)"
                    onChange={handleChange}
                    value={value.phone}
                    error={extractGraphqlError([ 'phone' ], errors)}
                />
            )}
        </Form>
    )
}

AddressForm.defaultProps = {
    showPhone: true
}