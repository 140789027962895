import React, { useContext, useState } from 'react';
import { Button } from '@platformapp/ui';
import { ApiContext } from '../API';
import { CartContext } from '../context/Cart';
import toast from 'react-hot-toast';
import { isNil } from 'ramda';

export const FreeCart = () => {
    const { cart } = useContext(CartContext);
    const {request} = useContext(ApiContext);
    const [working, setWorking] = useState(false);

    const complete = async () => {
        setWorking(true);
        const res = await request({
            query: 'COMPLETE_FREE',
            variables: {
                input: {
                    cartId: cart.id
                }
            }
        });

        const successUrl = res?.completeFreeCart.successUrl;

        if (!isNil(successUrl)) {
            window.location.replace(successUrl);
        } else {
            toast.error('Failed to complete checkout');
        }
    }

    return (<>
        <p>This cart does not require payment.</p>
        <div className="mt-5">
            <Button
                primary
                type="submit"
                loading={working}
                width="fluid"
                height="large"
                onClick={complete}
            >
                <div className="flex items-center justify-center">
                    Complete free cart
                </div>
            </Button>
        </div>
    </>)
}