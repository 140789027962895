import React, { useContext, useEffect } from 'react';
import {Button, Form, TextField} from '@platformapp/ui';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { concat, pathOr, pick, propOr } from 'ramda';
import { CartContext } from '../context/Cart';
import { ApiContext } from '../API';
import { extractGraphqlError } from '../util';
import { AddressForm } from '../common/AddressForm';
import toast from 'react-hot-toast';

const extractAddressErrors = (errors, key) => {
    if (!Array.isArray(errors)) {
        return [];
    }
    return errors
        .filter(err => err.path.length === 3 && err.path[0] === 'input' && err.path[1] === key)
        .map(err => ({
            ...err,
            path: [err.path[2]]
        }))
}

export const CustomerInformation = () => {
    const history = useHistory();
    const { cart, store, update, makePath } = useContext(CartContext);
    const apiCtx = useContext(ApiContext);

    useEffect(() => {
        document.title = `Information - ${store.name} - checkout`
    }, []);

    const addressKey = cart.requiresShipping ? 'shippingAddress' : 'billingAddress';

    const form = useFormik({
        enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
            email: propOr('', 'email', cart),
            [addressKey]: {
                name: pathOr('', [ addressKey, 'name' ], cart),
                line1: pathOr('', [ addressKey, 'line1' ], cart),
                line2: pathOr('', [ addressKey, 'line2' ], cart),
                city: pathOr('', [ addressKey, 'city' ], cart),
                country: pathOr('', [ addressKey, 'country' ], cart),
                postalCode: pathOr('', [ addressKey, 'postalCode' ], cart),
                phone: pathOr('', [ addressKey, 'phone' ], cart),
            }
        },
        validate: (values) => {
            const errors = [];

            const addError = (path, message) => errors.push({ path: path, message: message });

            // Email
            if (!values.email || values.email.length === 0) {
                addError([ 'input', 'email' ], 'Required');
            } else if (values.email.length < 8) {
                addError([ 'input', 'email' ], 'Too short');
            } else if (values.email.length > 200) {
                addError([ 'input', 'email' ], 'Too long');
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                addError([ 'input', 'email' ], 'Invalid email address');
            }

            // Name
            if (!values[addressKey].name || values[addressKey].name.length === 0) {
                addError([ 'input', addressKey, 'name' ], 'Required');
            } else if (values[addressKey].name.length < 2) {
                addError([ 'input', addressKey, 'name' ], 'Too short');
            } else if (values[addressKey].name.length > 50) {
                addError([ 'input', addressKey, 'name' ], 'Too long');
            }

            // Line 1
            if (!values[addressKey].line1 || values[addressKey].line1.length === 0) {
                addError([ 'input', addressKey, 'line1' ], 'Required');
            }

            // City
            if (!values[addressKey].city || values[addressKey].city.length === 0) {
                addError([ 'input', addressKey, 'city' ], 'Required');
            }

            // Country
            if (!values[addressKey].country || values[addressKey].country.length === 0) {
                addError([ 'input', addressKey, 'country' ], 'Required');
            }

            // Postal code
            if (!values[addressKey].postalCode || values[addressKey].postalCode.length === 0) {
                addError([ 'input', addressKey, 'postalCode' ], 'Required');
            }

            if (errors.length > 0) {
                console.debug('Validation errors:', errors);
            }

            return errors;
        },
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            console.debug('Submit', values);
            setSubmitting(true);

            try {
                // Make request to update checkout
                const res = await apiCtx.request({
                    query: cart.requiresShipping ? 'UPDATE_SHIPPING' : 'UPDATE_BILLING',
                    variables: {
                        cartId: cart.id,
                        email: values.email,
                        address: {
                            ...pick(['city', 'country', 'name', 'line1', 'postalCode'], values[addressKey]),
                            line2: values[addressKey].line2.length === 0 ? null : values[addressKey].line2,
                            phone: values[addressKey].phone.length === 0 ? null : values[addressKey].phone,
                        }
                    }
                });

                const errors = concat(res.updateCartEmail.userErrors, res.addressUpdate.userErrors);

                if (errors.length > 0) {
                    setErrors(errors);
                    return null;
                }

                // Update context
                update(res.addressUpdate.cart);

                // Redirect to payment/shipping
                const nextPath = cart.requiresShipping
                    ? '/shipping'
                    : '/payment';
                history.push(makePath(nextPath));
            } catch (err) {
                console.error(err);
                setSubmitting(false);
                toast.error('An error occurred saving your details.');
            }
        },
    });

    return (
        <form
            onSubmit={form.handleSubmit}
            noValidate
            className="fade-in"
        >
            <h2 className="mb-5 font-medium">Contact information</h2>
            <Form>
                <TextField
                    disabled={form.isSubmitting}
                    name="email"
                    label="Email"
                    type="email"
                    value={form.values.email}
                    onChange={form.handleChange}
                    error={extractGraphqlError([ 'input', 'email' ], form.errors)}
                />
                <TextField
                    disabled={form.isSubmitting}
                    name={`${addressKey}.phone`}
                    label="Phone (optional)"
                    value={form.values[addressKey].phone}
                    onChange={form.handleChange}
                    error={extractGraphqlError([ 'input', addressKey, 'phone' ], form.errors)}
                />
            </Form>

            <div className="mt-8">
                <h2 className="mb-5 font-medium">
                    {cart.requiresShipping ? 'Shipping' : 'Billing'} address
                </h2>
                <AddressForm
                    disabled={form.isSubmitting}
                    errors={extractAddressErrors(form.errors, addressKey)}
                    onChange={value => form.setFieldValue(addressKey, value)}
                    showPhone={false}
                    value={form.values[addressKey]}
                />
            </div>

            <div>
                <Button
                    primary
                    type="submit"
                    width="fluid"
                    className="mt-4"
                    loading={form.isSubmitting}
                    height="large"
                >
                    {cart.requiresShipping && 'Continue to shipping'}
                    {!cart.requiresShipping && 'Continue to payment'}
                </Button>
            </div>
        </form>
    )
}