import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CartContext } from '../context/Cart';
import { StripePaymentWrapper } from '../stripe/StripePaymentWrapper';
import { FreeCart } from './FreeCart';

export const Payment = () => {
    const { cart, store, hasValidInformation, hasValidShippingRate, makePath } = useContext(CartContext);
    const history = useHistory();

    useEffect(() => {
        if (!hasValidInformation) {
            history.push(makePath());
        } else if (!hasValidShippingRate) {
            history.push(makePath('/shipping'));
        }
        document.title = `Payment - ${store.name} - checkout`
    }, []);

    return (
        <div className="fade-in">
            {cart.totalPrice.amount === 0 && (
                <FreeCart />
            )}
            {cart.totalPrice.amount > 0 && (
                <StripePaymentWrapper />
            )}
        </div>
    )
}