import React, { useContext } from 'react';
import {CartSummary} from '../cart/CartSummary';
import {CustomerInformation} from '../information/CustomerInformation';
import {Payment} from '../payment/Payment';
import {Shipping} from '../shipping/Shipping';
import { CartContext } from '../context/Cart';
import { Route, Switch } from 'react-router-dom';
import {Breadcrumb} from './Breadcrumb';
import { FullScreenError } from '../common/FullScreenError';
import { GenericFullScreenError } from '../common/GenericFullScreenError';
import { SkeletonHeadingText, SkeletonParagraphText } from '@platformapp/ui';

const InitialLoader = () => (
    <>
        <SkeletonParagraphText />

        <SkeletonHeadingText className="mt-16" size="large" width="full" />
        <SkeletonHeadingText className="mt-8" size="large" width="full" />

        <SkeletonHeadingText className="mt-16" size="large" width="full" />
    </>
)

export const Checkout = () => {
    const checkoutCtx = useContext(CartContext);

    if (checkoutCtx.isError) {
        return <GenericFullScreenError />
    }

    if (!checkoutCtx.isLoading && !checkoutCtx.checkout) {
        return <FullScreenError
            title="The checkout you're looking for does not exist."
            information="It may have been paid for or expired. Please try returning to the store page you came from, and continue shopping."
        />
    }

    if (!checkoutCtx.isLoading && checkoutCtx.checkout.items.edges.length === 0) {
        return <FullScreenError
            title="The cart doesn't contain any items."
            information="Add items then try checking out again."
        />
    }

    return (
        <div className="container">
            <div className="md:grid grid-cols-2 gap-40 fade-in md:min-h-screen shadow-before">
                <div className="pt-10">
                    <CartSummary />
                </div>
                <div className="pt-10 pb-16 shadow-before">
                    {checkoutCtx.isLoading && <InitialLoader />}
                    {!checkoutCtx.isLoading && (<>
                        <Switch>
                            <Route exact path={[
                                '/:projectId/:checkoutId',
                                '/:projectId/:checkoutId/shipping',
                                '/:projectId/:checkoutId/payment',
                             ]}>
                                <Breadcrumb />
                            </Route>
                        </Switch>
                        <Switch>
                            <Route
                                exact
                                path="/:projectId/:checkoutId"
                                component={CustomerInformation}
                            />
                            <Route
                                exact
                                path="/:projectId/:checkoutId/shipping"
                                component={Shipping}
                            />
                            <Route
                                exact
                                path="/:projectId/:checkoutId/payment"
                                component={Payment}
                            />
                        </Switch>
                    </>)}
                </div>
            </div>
        </div>
    )
}