import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {Checkout} from "./checkout/Checkout";
import './styles/index.css';
import './styles/animation.css';
import { ApiProvider } from './API';
import { CartProvider } from './context/Cart';
import { ToastContainer } from '@platformapp/ui';
import { ErrorBoundary } from "./common/ErrorBoundary";
import { FullScreenError } from "./common/FullScreenError";

const CheckoutWrapper = () => (
	<CartProvider>
		<ToastContainer />
		<Checkout />
	</CartProvider>
)

export const App = () => (
	<ErrorBoundary>
		<BrowserRouter basename="/">
			<Switch>
				<Route
					exact
					path="/:projectId/:checkoutId/:step?"
				>
					<ApiProvider>
						<Route path="/:projectId/:checkoutId/:step?" exact component={CheckoutWrapper} />
					</ApiProvider>
				</Route>
				<Route>
					<FullScreenError
						title="Page not found"
						information="Try going back to the store."
					/>
				</Route>
			</Switch>
		</BrowserRouter>
	</ErrorBoundary>
)