import { is, isEmpty, startsWith, propEq, filter } from 'ramda';

export const extractGraphqlError = (path, errors, startComparison = false) => {
    // Path might just be a single string if the field is only 1 level deep
    const pathArray = is(String, path) ? [ path ] : path;

    if (isEmpty(errors)) {
        return [];
    }

    const comparator = startComparison
        ? x => startsWith(pathArray, x.path)
        : propEq('path', pathArray);

    return filter(comparator, errors).map(x => x.message);
}