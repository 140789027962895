import React, { useContext } from 'react';
import { Steps, Step } from '@platformapp/ui';
import { Link, useRouteMatch } from 'react-router-dom';
import { CartContext } from '../context/Cart';

export const Breadcrumb = () => {
    const {cart, makePath} = useContext(CartContext);
    const match = useRouteMatch();

    const stepMap = {
        '/:projectId/:checkoutId': 0,
        '/:projectId/:checkoutId/shipping': 1,
        '/:projectId/:checkoutId/payment': 2,
    }

    return (
        <Steps
            activeStep={stepMap[match.path]}
            className="mb-10"
        >
            <Step
                completedWrapper={children => (
                    <Link
                        to={makePath()}
                    >
                        {children}
                    </Link>
                )}
                label="Information"
            />
            {cart.requiresShipping && (
                <Step
                    completedWrapper={children => (
                        <Link
                            to={makePath('/shipping')}
                        >
                            {children}
                        </Link>
                    )}
                    label="Shipping"
                />
            )}
            <Step
                label="Payment"
            />
        </Steps>
    )
}