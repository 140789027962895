import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ApiContext } from '../API';
import { formatMoney } from '../util/money';
import { CartContext } from '../context/Cart';
import { pathOr } from 'ramda';
import { Button, RadioField, SkeletonHeadingText } from '@platformapp/ui';
import toast from 'react-hot-toast';

const OPTION_CLASSES = 'block py-4 px-5 flex items-center border-b border-gray-300 last:border-b-0';

const ShippingRateOption = ({
    disabled,
    form,
    rate
}) => {
    const selected = rate.id === form.values.shippingRateId;
    const handleSelect = () => form.setFieldValue('shippingRateId', rate.id);
    return (
        <div
            className={`${OPTION_CLASSES} ${selected && !disabled && 'bg-indigo-100'} ${disabled ? 'bg-gray-200' : 'cursor-pointer'}`}
            onClick={handleSelect}
        >
            <div className="flex-grow">
                <RadioField
                    disabled={disabled}
                    checked={selected}
                    label={rate.name}
                    name="shippingRateId"
                    onChange={handleSelect}
                />
            </div>
            <p className="flex-shrink-0">
                {rate.price.amount === 0 ? 'Free' : formatMoney(rate.price)}
            </p>
        </div>
    )
}

const ShippingRates = ({
    form,
    rates
}) => (
    <div className="mb-10">
        {!rates.isLoading && (
            <>
                {rates.data.rates.length > 0 && (
                    <div className="rounded-md border border-gray-300 overflow-hidden">
                        {rates.data.rates.map(rate => (
                            <ShippingRateOption
                                disabled={form.isSubmitting}
                                form={form}
                                key={rate.id}
                                rate={rate}
                            />
                        ))}
                    </div>
                )}

                {rates.data.rates.length === 0 && (
                    <div className="rounded-md bg-blue-100 px-4 py-3">
                        <p className="text-blue-700">Sorry, shipping to your region is not available.</p>
                    </div>
                )}
            </>
        )}

        {rates.isLoading && (
            <div className="mb-10">
                <SkeletonHeadingText className="mb-4" size="large" width="full" />
                <SkeletonHeadingText size="large" width="full" />
            </div>
        )}
    </div>
)

export const Shipping = () => {
    const apiCtx = useContext(ApiContext);
    const history = useHistory();
    const { checkout, store, update, makePath } = useContext(CartContext);

    useEffect(() => {
        // Ensure cart has valid email and shipping address
        if (!checkout.email || !checkout.shippingAddress) {
            history.push(makePath());
        }

        // Set page title
        document.title = `Shipping - ${store.name} - checkout`;

        // Fetch latest shipping rates
        (async () => {
            const res = await apiCtx.request({
                query: 'GET_SHIPPING_RATES',
                variables: {
                    id: checkout.id
                }
            });
            setRates({
                isLoading: false,
                data: res.checkout.availableShippingRates
            });
        })();
    }, []);

    const [ rates, setRates ] = useState({
        isLoading: true,
    });

    const defaultRate = !rates.isLoading && rates.data.rates.length > 0 ? rates.data.rates[0].id : null;

    const form = useFormik({
        enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
            shippingRateId: pathOr(defaultRate, [ "shippingRate", "id" ], checkout),
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
                // Make request to update shipping rate
                const res = await apiCtx.request({
                    query: 'UPDATE_SHIPPING_RATE',
                    variables: {
                        input: {
                            cartId: checkout.id,
                            shippingRateId: values.shippingRateId
                        }
                    }
                });

                update(res.updateCartShippingRate.cart);

                // Redirect to payment
                history.push(makePath('/payment'));
            } catch (err) {
                console.error(err);
                setSubmitting(false);
                toast.error('An error occurred saving the shipping rate.');
            }
        },
    });

    return (
        <form onSubmit={form.handleSubmit} noValidate className="fade-in">
            <ShippingRates
                form={form}
                rates={rates}
            />

            <div>
                <Button
                    disabled={!rates.isLoading && rates.data.rates.length === 0}
                    primary
                    type="submit"
                    loading={rates.isLoading || form.isSubmitting}
                    width="fluid"
                    height="large"
                >
                    Continue to payment
                </Button>
            </div>
        </form>
    )
}