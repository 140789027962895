import Dinero from "dinero.js";

export const formatMoney = (price, includeSymbol = true) => {
    const dineroVal = Dinero(price);

    if (includeSymbol) {
        return dineroVal.toFormat("$0,0.00");
    }

    return dineroVal.toFormat("0,0.00");
}