import { SkeletonHeadingText, SkeletonParagraphText } from '@platformapp/ui';
import React, { useContext } from 'react';
import { TestModeBadge } from '../common/TestModeBadge';
import { CartContext } from '../context/Cart';
import { formatMoney } from '../util/money';

const getPrice = (item) => {
    if (item.onSale && item.salePrice) {
        return item.salePrice;
    }
    return item.price;
}

const CartItem = ({
    item
}) => (
    <div
        className="mb-5 last:mb-0"
    >
        <div className="flex mb-1 items-center">
            <div className="w-15 h-15 bg-gray-100 shadow-xs rounded-sm overflow-hidden mr-3">
                {item.variant.images.edges.length > 0 && (
                    <img
                        src={item.variant.images.edges[0].node.url}
                    />
                )}
            </div>
            <div className="flex-grow">
                {item.variant.options.length === 0 && (
                    <p className="font-semibold">{item.variant.product.name}</p>
                )}
                {item.variant.options.length > 0 && (
                    <p className="font-semibold">{item.variant.product.name} <span className="text-sm font-normal text-gray-600">- {item.variant.options.join(' / ')}</span></p>
                )}
                <p className="text-gray-600 mt-1">
                    Qty: {item.quantity}
                </p>
            </div>
            {item.variant.onSale && item.variant.salePrice && (
                <p className="text-gray-600 line-through mr-3">
                    {formatMoney({
                        amount: item.variant.price.amount * item.quantity,
                        currency: item.variant.price.currency
                    })}
                </p>
            )}
            <p className="flex-shrink-0">
                {formatMoney({
                    amount: getPrice(item.variant).amount * item.quantity,
                    currency: item.variant.price.currency
                })}
            </p>
        </div>
    </div>
)

export const CartSummary = () => {
    const checkoutCtx = useContext(CartContext);

    if (checkoutCtx.isLoading) {
        return (
            <>
                <SkeletonHeadingText className="mb-16" size="large" />

                <SkeletonParagraphText lines={2} />
                <SkeletonParagraphText className="mt-12" lines={2} />

                <SkeletonParagraphText className="mt-16 w-1/2" />
                <SkeletonParagraphText className="mt-8 w-1/2" />
            </>
        );
    }

    return (
        <>
            <div className="flex items-center mb-10">
                <h1>
                    {checkoutCtx.store.name}
                </h1>
                {!checkoutCtx.store.isLiveMode && <TestModeBadge />}
            </div>

            <div>
                {(
                    checkoutCtx.cart.items.edges.map(({ node }) => (
                        <CartItem
                            item={node}
                            key={node.id}
                        />
                    ))
                )}
            </div>

            <div className="pt-6 mt-6">
                <div className="flex mb-2">
                    <p className="flex-grow font-medium text-gray-700">Sub total</p>
                    <p className="flex-shrink-0">
                        {formatMoney(checkoutCtx.cart.subTotalPrice)}
                    </p>
                </div>
                {checkoutCtx.cart.requiresShipping && (
                    <div className="flex">
                        <p className="flex-grow font-medium text-gray-700">Shipping</p>
                        {checkoutCtx.cart.shippingRate && (
                            <p className="flex-shrink-0">
                                {formatMoney(checkoutCtx.cart.shippingRate.price)}
                            </p>
                        )}
                        {!checkoutCtx.cart.shippingRate && (
                            <p className="flex-shrink-0 text-gray-600 text-sm">Calculated in next step</p>
                        )}
                    </div>
                )}
            </div>
            <div className="mt-4">
                <div className="flex items-center">
                    <p className="flex-grow font-medium text-gray-700">Total</p>
                    <p className="flex-shrink-0 text-lg font-semibold">
                        {formatMoney(checkoutCtx.cart.totalPrice)}
                    </p>
                </div>
            </div>
        </>
    )
}