import React from 'react';

export const FullScreenError = ({
    title,
    information
}) => (
    <div className="absolute h-full w-full flex flex-col items-center justify-center">
        <div className="max-w-md">
            <p className="text-center font-medium text-lg text-gray-700">
                {title}
            </p>
            <p className="text-center mt-4 text-gray-600">
                {information}
            </p>
        </div>
    </div>
)