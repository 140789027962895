import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Field } from '@platformapp/ui';
import { clone } from 'ramda';

const ELEMENT_DEFAULT_OPTIONS = {
    classes: {
        base: 'rounded border border-gray-400 bg-white cursor-text px-3 py-3 transition-shadow duration-200',
        focus: 'border-blue-700 shadow-outline',
    },
    style: {
        base: {
            ':disabled': {
                color: 'rgb(26, 32, 44)'
            },
            color: 'rgb(26, 32, 44)',
            fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif',
        },
    }
}

const getOptions = (disabled) => {
    const options = clone(ELEMENT_DEFAULT_OPTIONS);

    if (disabled) {
        options.classes.base += ' bg-gray-200';
    } else {
        options.classes.base += ' hover:border-blue-700';
    }

    return {
        disabled,
        ...options
    }
}

export const CardNumberField = ({
    disabled,
    ...props
}) => (
    <Field {...props}>
        <CardNumberElement
            options={getOptions(disabled)}
        />
    </Field>
)

export const CardExpiryField = ({
    disabled,
    ...props
}) => (
    <Field {...props}>
        <CardExpiryElement
            options={getOptions(disabled)}
        />
    </Field>
)

export const CardCvcField = ({
    disabled,
    ...props
}) => (
    <Field {...props}>
        <CardCvcElement
            options={getOptions(disabled)}
        />
    </Field>
)