import React, { useContext, useState, useEffect } from 'react';
import { Elements, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ApiContext } from '../API';
import { CartContext } from '../context/Cart';
import { Button } from '@platformapp/ui';
import { extractGraphqlError } from '../util';
import { formatMoney } from '../util/money';
import { PAYMENT_STATUS_ERROR, PAYMENT_STATUS_REQUIRES_ACTION, PAYMENT_STATUS_SUCCESS } from '../util/constants';
import { CardCvcField, CardExpiryField, CardNumberField } from '../stripe/ElementFields';
import Padlock from '../icons/lock.svg';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';

const GENERIC_PAYMENT_ERROR = 'An error occurred processing payment.';

export const StripePaymentWrapper = () => {
    const [ stripePromise, setStripePromise ] = useState(null);
    const { checkout, store } = useContext(CartContext);
    const stripeGateway = checkout.availablePaymentGateways.find(gw => gw.__typename === 'StripePaymentGateway');

    useEffect(() => {
        if (stripeGateway) {
            const publishableKey = store.isLiveMode
                ? process.env.STRIPE_PUBLISHABLE_KEY_LIVE
                : process.env.STRIPE_PUBLISHABLE_KEY_TEST;
            setStripePromise(loadStripe(publishableKey, {
                stripeAccount: stripeGateway.accountId
            }));
        }
    }, []);

    if (!stripeGateway) {
        return <p>No payment gateway configured for this store.</p>
    }

    if (!stripePromise) {
        return <p>Loading</p>;
    }

    return (
        <Elements
            stripe={stripePromise}
        >
            <StripePayment />
        </Elements>
    )
}

const StripePayment = () => {
    const apiCtx = useContext(ApiContext);
    const { checkout } = useContext(CartContext);
    const stripe = useStripe();
    const elements = useElements();

    const handlePlaceOrderClick = async () => {        
        const cardElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.debug('Stripe payment method creation error', error);

            let newErrors = [];

            switch (error.code) {
                case 'invalid_expiry_month':
                case 'invalid_expiry_year':
                case 'incomplete_expiry':
                case 'invalid_expiry_year_past':
                case 'invalid_expiry_month_past':
                    newErrors = [
                        {
                            path: [ 'expiry' ],
                            message: error.message
                        }
                    ];
                    break;
                case 'invalid_cvc':
                case 'incomplete_cvc':
                case 'incorrect_cvc':
                    newErrors = [
                        {
                            path: [ 'cvc' ],
                            message: error.message
                        }
                    ];
                    break;
                default:
                    newErrors = [
                        {
                            path: [ 'cardNumber' ],
                            message: error.message
                        }
                    ];
                    break;
            }

            return {
                success: false,
                errors: newErrors
            }
        } else {
            try {
                // Make first attempt to complete checkout
                const res = await apiCtx.request({
                    query: 'COMPLETE_STRIPE',
                    variables: {
                        input: {
                            cartId: checkout.id,
                            stripePaymentMethodId: paymentMethod.id,
                        }
                    }
                });

                console.debug(res);

                if (res.completeCartWithStripe.userErrors.length > 0) {
                    toast.error(GENERIC_PAYMENT_ERROR);
                    return;
                }

                if (res.completeCartWithStripe.payment.status === PAYMENT_STATUS_REQUIRES_ACTION) {
                    // Further action (3D secure) is required
                    const resAfterAction = await stripe.handleCardAction(res.completeCartWithStripe.payment.paymentIntentClientSecret);

                    if (resAfterAction.error) {
                        return {
                            success: false,
                            errors: [
                                {
                                    path: [ 'cardNumber' ],
                                    message: resAfterAction.error.message
                                }
                            ]
                        }
                    } else {
                        // Make a final payment attempt, following user action
                        const res2 = await apiCtx.request({
                            query: 'COMPLETE_STRIPE',
                            variables: {
                                input: {
                                    cartId: checkout.id,
                                    paymentIntentId: resAfterAction.paymentIntent.id
                                }
                            }
                        });

                        if (res2.completeCartWithStripe.userErrors.length > 0) {
                            return {
                                success: false,
                                errors: res2.completeCartWithStripe.userErrors
                            }
                        } else {
                            window.location.replace(res.completeCartWithStripe.payment.successUrl);
                        }
                    }
                } else if (res.completeCartWithStripe.payment.status === PAYMENT_STATUS_SUCCESS) {
                    window.location.replace(res.completeCartWithStripe.payment.successUrl);
                } else if (res.completeCartWithStripe.payment.status === PAYMENT_STATUS_ERROR) {
                    return {
                        success: false,
                        errors: [
                            {
                                path: [ 'cardNumber' ],
                                message: res.completeCartWithStripe.payment.errorMessage || GENERIC_PAYMENT_ERROR
                            }
                        ]
                    }
                } else {
                    toast.error(GENERIC_PAYMENT_ERROR);
                }
            } catch (err) {
                console.error(err);
                toast.error(GENERIC_PAYMENT_ERROR);
            }
        }

        return {
            success: true
        }
    }

    const form = useFormik({
        enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
        initialValues: {},
		onSubmit: async (_, {setErrors}) => {
            const res = await handlePlaceOrderClick();
            if (!res.success) {
                setErrors(res.errors);
            }
        }
    });

    if (!stripe || !elements) {
        return <p>Loading</p>
    }

    return (
        <>
            <form onSubmit={form.handleSubmit}>
                <CardNumberField
                    disabled={form.isSubmitting}
                    label="Card number"
                    error={extractGraphqlError([ 'cardNumber' ], form.errors)}
                />

                <div className="grid grid-cols-2 gap-4">
                    <CardExpiryField
                        disabled={form.isSubmitting}
                        label="Expiry date"
                        error={extractGraphqlError([ 'expiry' ], form.errors)}
                    />
                    <CardCvcField
                        disabled={form.isSubmitting}
                        label="Security code"
                        error={extractGraphqlError([ 'cvc' ], form.errors)}
                    />
                </div>

                <div className="mt-2">
                    <Button
                        primary
                        type="submit"
                        loading={form.isSubmitting}
                        width="fluid"
                        height="large"
                    >
                        <div className="flex items-center justify-center">
                            <Padlock className="fill-current mr-2" />
                            <span>Pay {formatMoney(checkout.totalPrice)}</span>
                        </div>
                    </Button>
                </div>
            </form>
        </>
    )
}